.bottom-space {
  height: 110px;
  margin-top: 15px;
}

.bottom-menu {
  padding: 0;
  width: 100%;
  overflow: visible;
  height: calc(73px + env(safe-area-inset-bottom));
  padding-bottom: env(safe-area-inset-bottom);
  &::after {
    content: '';
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: env(safe-area-inset-bottom);
    background-color: white;
    z-index: 2001;
    box-shadow: 0 0 2px #0000004D;
  }
  .bottom-menu-wrapper{
    height: 73px;
    width: 100%;
    background-position: center 3px;
    background-repeat: no-repeat;
    background-size: auto 73px;
    padding-top: 7px;
    z-index: 2000;
    &.empty-action {
      background-color: transparent;
      position: relative;
      &::after {
        content: '';
        position: absolute;
        z-index: -1;
        bottom: 0;
        left: 0;
        width: 100%;
        background-color: white;
        height: 64px;
        box-shadow: 0 0 6px #0000004D;
      }
    }
    ul {
      display: flex;
      align-items: center;
      width: 100%;
      justify-content: space-around;
      margin: 0;
      padding: 0;
      list-style: none;
      gap: 0;
      height: 66px;
      li {
        text-align: center;
        flex-grow: 0;
        width: calc(100% / 5);
        padding: 0 5px;
        overflow: hidden;
        a {
          text-decoration: none;
          .icon {
            height: 26px;
            margin-bottom: 3px;
            svg {
              max-height: 100%;
              width: auto;
            }
          }
          p {
            font-size: 11px;
            margin: 0;
            padding: 0;
            font-weight: bold;
            color: #00000080;
          }
        }
        &.main-link {
          flex-grow: 0;
          position: relative;
          padding: 3px;
          background-color: white;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-top: -68px;
          border: 1px solid var(--primary-color);
          width: 66px;
          height: 66px;
          border-radius: 50%;
          margin-left: 3px;
          margin-right: 3px;
          box-shadow: 0 3px 6px #00000033;
          svg,
          p {
            font-size: 10px;
            color: var(--primary-color);
            margin: 2px 0 0 0;
            font-weight: bold;
          }
        }
        &.active {
          box-shadow: 0 3px 6px #00000033;
          padding: 18px 5px;
          border-radius: 7px;
          background-color: white;
        }
      }
    }
  }
}