@import 'styles/colors';

.slides{
  .slide-step {
    display: flex;
    gap: 10px;
    span {
      width: 6px;
      height: 6px;
      border-radius: 50%;
      background-color: $gray;
      &.active {
        background-color: var(--primary-color);
      }
    }
  }
  .slide-img {
    display: flex;
    padding: 0 2rem;
    img {
      max-width: 100%;
      height: auto;
      max-height: 35vh;
    }
  }
}