@import 'src/styles/colors';

$box-shadow: 0px 1px 2px 0 rgba(0, 0, 0, 0.16);

.embed-pdf{
  width: 100%;
  .document {
    background-color: #fff;
    position: relative;
    .react-pdf__Document{
      box-shadow: $box-shadow;
      overflow: auto;
      height: 70vh;
    }
  }
  .document-zoom,
  .pager{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 25px;
    a,
    div{
      cursor: pointer;
      margin: 0;
      border-radius: 50%;
      width: 32px;
      height: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
      &.btn-page{
        background-color: $secondary;
      }
      box-shadow: $box-shadow;
      &.white{
        background-color: white !important;
        svg,
        svg *{
          color: $secondary !important;
          stroke: $secondary !important;
        }
      }
      svg,
      img{
        width: auto;
        height: 18px;
      }
      &.disabled {
        opacity: 0.5;
        cursor: not-allowed;
      }
    }
  }
  .document-zoom{
    position: absolute;
    right: 5px;
    width: 75px;
    bottom: 5px;
    gap: 10px;
    z-index: 10;
    opacity: 0.95;
  }
  .pager{
    position: relative;
    .btn-page{
      &.rotate{
        transform: rotate(180deg);
      }
    }
    .download{
      position: absolute;
      right: 0;
      top: 0;
    }
  }
}