@import 'styles/colors';
@import 'styles/variables';

.deadlines-box {
    &-header {
        ul {
            margin-left: 0;
            padding-left: 15px;
        }

        display: flex;
        justify-content: space-between;

        &-left {
            &-header {
                display: flex;
                align-items: center;
                gap: 1rem;
                
                .product-icon {
                    max-height: 30px;
                }
            }
        }
    }

    &-progress {
        &-header {
            display: flex;
            align-items: center;
            justify-content: space-between;
            &.without-pending{
                justify-content: flex-start;
                & > div {
                    flex-basis: 50%;
                }
            }

            p {
                font-size: 14px;
            }

            h4 {
                font-size: 18px;
            }

            div {
                p {
                    margin-bottom: 0;
                }
            }
        }
    }

    &-deadline {
        display: flex;
        gap: 1rem;
        align-items: center;

        &-left,
        &-right {
            width: 50%;
        }

        p {
            font-size: 12px;
            margin-bottom: 0;
        }

        .btn {
            padding: 5px 25px;
            font-size: .75rem;
            text-transform: none;
        }
    }

    .progress-chart-container {
        width: 100%;
        height: 1rem;
        background: $gray-light;
        box-shadow: 0px 0px 3px #00000033;
        border-radius: 5px;

        &-value {
            width: 20%;
            height: 1rem;
            background: var(--primary-color);
            border-radius: 5px;
        }
    }

    .circle-chart__container {
        width: 90px;
        height: 90px;
        border-radius: 50%;
        padding: 5px;
        box-shadow: $shadow;
        .circle-chart {
            width: 100%;
            height: 100%;
            display: flex;
            border-radius: 50%;
            background: conic-gradient(var(--primary-color) 36deg, #DFDEDE 0deg);
            font-size: 0;
        }

        .circle-chart::after {
            content: attr(data-title);
            display: flex;
            justify-content: center;
            flex-direction: column;
            width: 100%;
            margin: 3px;
            border-radius: 50%;
            background: white;
            font-size: 1rem;
            text-align: center;
            color: var(--primary-color);
            font-weight: bold;
        }
    }
}