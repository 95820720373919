.update-app {
  max-width: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  h1 {
    text-align: center;
  }
  a,
  button {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    img {
      height: 30px;
      width: auto;
    }
  }
}