.image-processing {
  position: relative;
  display: inline-block; /* Asegúrate de que el contenedor sea del tamaño de la imagen */
  img {
    width: 100%;
    height: auto;
  }
}

.scan-animation {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  // height: 3px; /* Altura de la línea de escáner */
  border: 3px solid var(--primary-color);
  box-shadow: 0 0 10px 0 var(--primary-color);
  animation: scan 3s linear infinite; /* Ajusta la duración de la animación según prefieras */

  .scan-icon {
    display: block;
    position: absolute;
    left: 50%; /* Centra la imagen horizontalmente */
    top: 50%; /* Centra la imagen verticalmente */
    transform: translate(-50%, -50%); /* Ajusta la posición exacta de la imagen */
    width: 60px;
    height: 60px; 
  }
}

@keyframes scan {
  0%, 100% {
    top: 0; /* Empieza en la parte superior de la imagen */
  }
  50% {
    top: 100%; /* Llega a la parte inferior de la imagen */
  }
}

/* Opcional: si quieres que la línea de escáner 'rebote' en la parte inferior y superior, puedes ajustar los keyframes */
@keyframes scan {
  0% {
    top: -3px; /* Empieza un poco antes para que la línea no se vea al inicio */
  }
  50% {
    top: calc(100% - 3px); /* Ajusta para que la línea no se desplace fuera de la imagen en la parte inferior */
  }
  100% {
    top: -3px; /* Vuelve a la posición inicial */
  }
}
.automatic-quoter {
  .camera-preview {
    width: 100%;
    height: 350px;
    aspect-ratio: 1/1;
    position: relative;

    .camera-preview-border-tl,
    .camera-preview-border-tr,
    .camera-preview-border-bl,
    .camera-preview-border-br{
      position: absolute;
      width: 35px;
      height: 35px;
      border-color: var(--primary-color);
      border-style: solid;
      &.camera-preview-border-tl {
        top: 0;
        left: 0;
        border-width: 3px 0 0 3px;
        border-radius: 15px 0 0 0;
      }
      &.camera-preview-border-tr {
        top: 0;
        right: 0;
        border-width: 3px 3px 0 0;
        border-radius: 0 15px 0 0;
      }
      &.camera-preview-border-br {
        bottom: 0;
        right: 0;
        border-width: 0 3px 3px 0;
        border-radius: 0 0 15px 0;
      }
      &.camera-preview-border-bl {
        bottom: 0;
        left: 0;
        border-width:  0 0 3px 3px;
        border-radius: 0 0 0 15px;
      }
    }
  }
}


.automatic-quoter__info-modal {
  .benefit-container {
    div {
      width: calc(100% - 30px);
    }

    svg {
      align-self: center;
    }
  }
}