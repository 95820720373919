// Fonts
$font-extrabold: 'Lato-black', sans-serif;
$font-bold: 'Lato-bold', sans-serif;
$font-regular: 'Lato', sans-serif;
$font-light: 'Lato-light', sans-serif;
$font-thin: 'Lato-thin', sans-serif;

// Spacing
$container-padding-x: 2.5rem;
$header-height: 66px;
$footer-height: 85px;
$footer-total-height: 160px;
$layout-padding: 16px;
$modal-padding: 15px;
$shadow: 0 3px 6px #00000033;
$radius: 5px;