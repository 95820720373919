@import url('https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400;700;900&display=swap');

h1,
.h1 {
  font-family: 'Lato', sans-serif;
  font-size: 22px;
  font-weight: bold;
}
h2,
.h2 {
  font-family: 'Lato', sans-serif;
  font-size: 20px;
  font-weight: bold;
}
h3,
.h3 {
  font-family: 'Lato', sans-serif;
  font-size: 18px;
  font-weight: bold;
}
h4,
.h4 {
  font-family: 'Lato', sans-serif;
  font-size: 16px;
  font-weight: bold;
}

p,
.p {
  font-family: 'Lato', sans-serif;
  font-size: 16px;
  color: #1D2226;
  line-height: 18px;
}

.text-default {
  color: #1D2226 !important;
}
.text-primary {
  color: var(--primary-color) !important;
}
.text-gray {
  color: #7F7F7F !important;
}
.text-gray-dark {
  color: $gray-dark !important;
}
.font-regular {
  font-weight: 400;
}
.font-bold {
  font-weight: bold;
}
.font-semibold {
  font-weight: 600;
}
.font-size-sm {
  font-size: 14px;
}
.font-size-xs {
  font-size: 12px;
}