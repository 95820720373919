@import 'styles/colors';
@import 'styles/variables';

.zoho-form {
    .crmWebToEntityForm {
        background: transparent !important;
        max-width: auto !important;
        color: auto !important;
        padding: 0 !important;

        .zcwf_title {
            color: inherit !important;
            font-size: 22px;
        }

        .zcwf_col_help {
            display: none;
        }

        .help-link {
            min-width: 115px;
        }

        .zcwf_row {
            margin-top: 0 !important;
            margin-bottom: 1rem !important;

            .zcwf_col_lab {
                color: inherit !important;
                font-size: 16px;
            }

            .zcwf_col_fld {
                width: 100% !important;
                font-size: 16px;
            }

            label {
                display: none;
            }
        }
        .formsubmit {
            background: var(--primary-color) !important;
            color: $white !important;
            border: var(--primary-color) !important;
            font-size: 16px;
        }
    }
}

.zsiq_floatmain {
    display: none !important;
}