$primary: #A6192E;
$primary-light: #A6192E85;
$secondary: #FFDEE6;
$warning: #FDAB51;
$light: #EDEDED;
$danger: #CD0E3B;
$green: #A1D042;
$success: #A1D042;  

$degraded-principal: var(--primary-color);

$gray: #D1D1D1;
$gray-light: #F9F9F9;
$gray-dark: #7B7B7B;

$black: #1A1A1A;

$red: #CD0E3B;
$red-light: #FFEAF0;

$gold: #AE7C32;

// $shadow: #00000017;
$white: #FFFFFF;

$body-color: #000000;
$bg-gray-light: #F4F4F4;
$bg-gray-medium: #66717d;
$bg-blue: #1367BE;
$bg-green-light: #F1FCDF;

$label-blue: #084B97;

$qr-btn-gradient: linear-gradient(180deg, #269298 0%, #1978B0 50%, #1367BE 100%);

// Modal background
$modal-bg: rgba(0, 0, 0, 0.4);
$modal-blur: 5px;

$filter-bg: #2CA1B1;