@import 'styles/colors';

.loader-wrapper {
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 9999;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;

  .blur-background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    backdrop-filter: blur(10px); /* El valor de desenfoque que prefieras */
    z-index: -1; /* Asegúrate de que esté detrás del loader */
  }

  &.hidden {
    display: none;
  }
}

@keyframes bblFadInOut {
  0%, 80%, 100% { box-shadow: 0 2.5em 0 -1.3em }
  40% { box-shadow: 0 2.5em 0 0 }
}
