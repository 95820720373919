@import 'styles/colors';

.ui-switch__container {
    display: flex;
    align-items: center;
    gap: 10px;

    .ui-switch-label {
        width: calc(100% - 60px - 1rem);
    }

    .ui-switch {
        position: relative;
        display: inline-block;
        width: 50px;
        height: 29px;

        .slider {
            position: absolute;
            cursor: pointer;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: #ccc;
            -webkit-transition: .4s;
            transition: .4s;
            border-radius: 34px;
        }

        .slider:before {
            position: absolute;
            content: "";
            height: 21px;
            width: 21px;
            left: 4px;
            bottom: 4px;
            background-color: white;
            -webkit-transition: .4s;
            transition: .4s;
            border-radius: 50%;
        }

        input:checked+.slider {
            background-color: #20CC7F;
        }

        input:focus+.slider {
            box-shadow: 0 0 1px #20CC7F;
        }

        input:checked+.slider:before {
            -webkit-transform: translateX(21px);
            -ms-transform: translateX(21px);
            transform: translateX(21px);
        }

        /* Rounded sliders */
        .slider.round {
            border-radius: 34px;
        }

        .slider.round:before {
            border-radius: 50%;
        }

        input {
            opacity: 0;
            width: 0;
            height: 0;
        }
    }

}