.download-app {
  max-width: 300px;
  margin: 25px auto;
  img.logo {
    display: block;
    margin: 10px auto 25px;
  }
  h1 {
    text-align: center;
  }
  a,
  button {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    img {
      height: 30px;
      width: auto;
    }
  }
}