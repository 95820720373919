.cards-carousel {
  .card-carousel {
    .order-1 {
      order: 1;
    }
    .order-0 {
      order: 0;
    }
    .card-image{
      img {
        max-width: 140px;
        height: auto;
        max-height: 100%;
      }
    }
  }
}