@import 'styles/colors';
@import 'styles/variables';

.contract-payment__container {
    .payment-logo {
        width: 90px;
    }
    .payment-input {
        stroke: $white;
        background-color: var(--primary-color);
        border-radius: 0.25rem;
        padding: 0.5rem;
    }
    .payment-input.disabled {
        background-color: $gray-light;
        border: 1px solid $gray;
        stroke: $gray;
    }
    .payment-input-error {
        color: $danger;
    }
    .payment-option-box {
        width: 100%;
        transition: border-color 0.5s ease;
        border: 2px solid transparent;
        &.disabled {
            opacity: 0.5;
        }
        &.selected {
            border-color: var(--primary-color);
        }
    }
    .grid-container {
        display: grid;
        grid-template-columns: repeat(2, 1fr);  /* Dos columnas iguales */
        gap: 16px;  /* Espacio entre los elementos */
      }
}