.payment-resume-center {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 90vh;
  .logo {
    position: absolute;
    top: 15px;
    left: 0;
    right: 0;
    margin: 0 auto;
  }
}
