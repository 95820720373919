@import "styles/variables";
@import "styles/colors";

.modal-wrapper {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: var(--z-index);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 0;
    padding: env(safe-area-inset-top) 0 env(safe-area-inset-bottom);

    &__background {
        position: fixed;
        z-index: -1;
        width: 100%;
        height: 100%;
        backdrop-filter: blur($modal-blur);

        &--dark {
            background-color: $modal-bg;
        }
    }
}