.ui-select {
  position: relative;
  &:not(.loading) {
    select {
      background-image: url('data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 fill=%27none%27 viewBox=%270 0 20 20%27%3e%3cpath stroke=%27%231A1A1A%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%271.5%27 d=%27M6 8l4 4 4-4%27/%3e%3c/svg%3e');
      background-position: right 5px center;
      background-repeat: no-repeat;
      background-size: 42px;
    }
  }
  label.chevron {
    position: absolute;
    top: 13px;
    right: 15px;
    .spinner-border{
      width: 24px;
      height: 24px;
    }
  }
}