@import 'styles/colors';
@import 'styles/variables';

.signup__container {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .steps-progress {
    width: 100%;
  }
}

.terms-and-conditions {
  background-color: $white;
  box-shadow: 0 0px 6px rgba(0, 0, 0, 0.3);
  padding: 30px;
  margin-left: -1.5rem;
  margin-right: -1.5rem;
  margin-bottom: -1.5rem;
  text-align: center;
}

.auth {
  .bg-image {
    background-image: url('https://source.unsplash.com/WEQbe2jBg40/600x1200');
    background-size: cover;
    background-position: center;
  }

  .login,
  .signup,
  .recover-password {
    min-height: calc(100vh - 6em);

    &-heading {
      font-weight: 300;
    }

    .main-logo {
      max-width: 120px;
      max-height: 120px;
    }

    &-terms {
      .ui-switch-label {
        color: var(--primary-color);
      }
    }
  }

  .btn-forgot-password {
    padding-right: 0;
    padding-top: 0;
    font-size: 0.7em;
  }

  .recover-password {
    padding-top: 15%;
  }
}
