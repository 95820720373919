.simple-card {
  &-image{
    width: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: auto;
  }
  &-title {
      font-size: 14px;
      font-weight: bold;
      margin-bottom: 0.25rem;
  }
  &-subtitle {
      font-size: 12px;
      margin-bottom: 0.25rem;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      max-width: 200px;
  }
  &-description {
      font-size: 10px;
      margin-bottom: 0;
      line-height: 12px;
  }
  * {
    text-decoration: none;
  }
}