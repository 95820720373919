@import '../../styles/variables';
@import '../../styles/colors';

.layout {
  .layout-wrapper {
    position: relative;
    .layout-right-menu{
      opacity: 0;
      width: 40%;
      position: absolute;
      top: 0;
      right: 0;
      z-index: 1;
      margin-top: 10px;
    }
    &.show-right-menu {
      display: flex;
      .layout-content{
        &::before {
          content: '';
          position: fixed;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          opacity: 1;
          z-index: 1000;
        }
      }
      .layout-right-menu {
        opacity: 1;
        nav {
          .links{
            overflow: auto;
            height: 100%;
            max-height: calc(75vh - 25px);
            overflow-x: hidden;
            
            &::-webkit-scrollbar {
              width: 4px;
              display: block;
            }
            
            &::-webkit-scrollbar-track {
              border-radius: 3px;
            }
            
            &::-webkit-scrollbar-thumb {
              background: #7F7F7F;
              border-radius: 3px;
            }
            .active p {
              color: var(--primary-color) !important;
            }
          }
          overflow-x: hidden;
        }
        &.with-user{
          .links{
            max-height: calc(75vh - 150px);
          }
        }
      }
    }
  }
  .header-wrapper {
    padding-top: calc(env(safe-area-inset-top) + 8px);
    background-color: white;
    box-shadow: $shadow;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 99;
    height: calc(66px + env(safe-area-inset-top));
    .container{
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .main-logo {
      max-height: 40px;
      max-width: 220px;
    }
    .actions-left,
    .actions-right {
      width: 50px;
      display: flex;
    }
    .actions-right {
      justify-content: flex-end;
    }
    .actions-left {
      justify-content: flex-start;
      .back-icon {
        path {
          color: var(--primary-color);
          fill: var(--primary-color);
        }
      }
    }
    .navbar-brand{
      margin: 0;
      flex-grow: 1;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .header-title{
      font-weight: 700;
      color: $body-color;
      max-width: calc(100% - 100px);
      text-align: center;
    }
    .menu-icon {
      position: relative;
      display: inline-block;
      width: 30px;
      height: 20px;
      cursor: pointer;
      .bar {
        position: absolute;
        width: 100%;
        height: 4px;
        background-color: var(--primary-color);
        border-radius: 2px;
        transition: 0.2s ease-in-out;
        &:first-child {
          top: 0;
        }
        &:nth-child(2) {
          top: 50%;
          transform: translateY(-50%);
        }
        &:last-child {
          bottom: 0;
        }
      }
      &.active {
        .bar {
          &:first-child {
            top: 50%;
            transform: translateY(-50%) rotate(45deg);
          }
          &:nth-child(2) {
            opacity: 0;
          }
          &:last-child {
            top: 50%;
            transform: translateY(-50%) rotate(-45deg);
          }
        }
      }
    }
  }

  .header-space {
    height: 90px;
  }
  &.no-header {
    margin-top: -1rem !important; 
    .header-space {
      height: 0;
    }
  }
}