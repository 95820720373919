@import "styles/variables";
@import "styles/colors";

.generic-modal {
    position: relative;
    background-color: #fff;
    border-radius: 5px;
    width: 90%;
    max-width: 500px;
    overflow-x: hidden;
    overflow-y: auto;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
    max-height: 85vh;

    .modal-wrapper__background--dark{
        background-color: rgba(0, 0, 0, 0.2);
    }

    &--full-screen {
        width: 100%;
        max-width: 100%;
        height: 100%;
        max-height: 100%;
        border-radius: 0;
    }

    &__header-content {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        gap: 10px;
        padding: $modal-padding $modal-padding 0;
    }

    &__title {
        color: var(--primary-color);
        font-family: $font-bold;
        font-size: 22px;
    }

    &__close-button {
        padding: 4px;
        border: none;
        outline: none;
        background-color: transparent;
        cursor: pointer;
        margin-left: auto;

        &--no-header {
            position: absolute;
            top: $modal-padding;
            right: $modal-padding;
        }

        i {
            line-height: normal;
            font-size: 22px;
            color: $bg-gray-medium;
        }
    }

    &__main-content {
        padding: 0 $modal-padding $modal-padding;

        &--full-padding {
            padding: $modal-padding;
        }

        &--no-content-padding {
            padding: 0;
        }
    }

    &__bottom-extra-content {
        width: 100%;
        padding: 0 $modal-padding $modal-padding;
    }
}