.stores-map-wrapper {
  height: 100%;
  overflow: hidden;
  position: relative;
  .map-wrapper{
    border-radius: 5px;
    overflow: hidden;
  }

  .map-wrapper {
    height: 100%;

    #map,
    iframe {
      width: 100%;
      height: 100%;
    }
  }
  .searcher{
    display: flex;
    gap: 15px;
    & > div {
      flex: 1;
    }
    button {
      width: 45px;
    }
  }
  .stores-loader{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
  }
}