@import 'styles/colors';

.Container {
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  margin-top: 5%;
  margin-right: auto;
  margin-bottom: 0px;
  margin-left: auto;
  max-width: 1280px;
  overflow-x: hidden;
  overflow-y: hidden;
}
.ErrorPage {
  min-height: 600px;

  &__image {
    position: relative;
  }
}
.ErrorPage__person {
  background-image: url("/assets/error.svg");
  height: 551px;
  width: 568px;
}
.ErrorPage__person--404 {
  background-image: url("/assets/error.svg");
}

.ErrorPage__person--500 {
  background-image: url("/assets/error.svg");
}

.ErrorPage__container {
  margin: auto;
  display: flex;
  position: relative;
}
.ErrorPage__code {
  color: var(--primary-color);
  font-weight: 700;
  position: absolute;
  top: 120px;
  right: 125px;
  font-size: 50px;
  opacity: 0.8;
}
.ErrorPage__content {
  margin-left: 50px;
}
.ErrorPage__title {
  font-size: 32px;
  line-height: 48px;
  width: 632px;
}
.ErrorPage__content ul {
  list-style: none;
  margin-left: 24px;
}
.ErrorPage__content ul li {
  padding-left: 16px;
  line-height: 27px;
}
.ErrorPage__content ul li::before {
  color: var(--primary-color);
  content: "•";
  display: inline-block;
  margin-left: -1em;
  width: 1em;
}

.decoration-underline {
  position: relative;
}
.decoration-underline::after {
  position: absolute;
  border-bottom-width: 3px;
  border-bottom-style: solid;
  border-bottom-color: var(--primary-color);
  bottom: 0;
  content: "";
  left: 0;
  width: 40px;
}

@media (max-width: 1140px) {
  .ErrorPage{
    &__container {
      flex-direction: column;
      align-items: center;
    }

    &__image {
      margin: 0;
    }

    &__content {
      margin-bottom: 70px;
    }
  }
}

@media (max-width: 768px) {
  .ErrorPage {
    &__container {
      width: 100%;
      flex-direction: column-reverse;
      align-items: center;

      .ErrorPage__image {
        display: none;
      }
    }

    &__title {
      width: 90%;
    }

    &__content {
      width: 90%;
    }
  }
}
