@import 'styles/colors';
@import 'styles/variables';

.home {
    margin-top: calc($container-padding-x * 0.5);
    .user-box {
        .box-content {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .user-box-info {
                display: flex;
                gap: 1rem;
                align-items: center;
                width: 100%;
                text-decoration: none;
                .user-box-image{
                    width: 40px;
                    height: 40px;
                    overflow: hidden;
                    border-radius: 50%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    background-size: contain;
                    background-repeat: no-repeat;
                    background-position: center;
                    img {
                        width: 100%;
                    }
                }
            }
        }
    }

    .shop-box {
        &-header {
            display: flex;
            justify-content: space-between;

            &-info {
                display: flex;
                gap: 1rem;
                align-items: center;

                &-container {
                    display: flex;
                    flex-direction: column;

                    &-name {
                        margin-bottom: 0;
                    }

                    &-address {
                        font-size: 10px;
                        font-weight: bold;
                        color: var(--primary-color);
                    }

                }
            }

            &-icons {
                display: flex;
                gap: 0.25rem;
                align-items: center;
            }
        }

        &-map {
            width: 100%;
        }

        &-actions {
            display: flex;
            justify-content: space-between;
            gap: 1rem;

            .btn {
                padding: 5px;
                font-size: .75rem;
                text-transform: none;
            }
        }

        &-list {
            ul {
                padding: 0 1rem;
            }
            li::marker {
                color: var(--primary-color);
            }
            &-item {
                display: flex;
                justify-content: space-between;
                align-items: center;
                font-weight: bold;
                gap: 1rem;

                &-products {
                    display: flex;
                    gap: 0.25rem;

                    img {
                        height: 18px;
                    }
                }
            }
        }
    }

    .loans {
        .box {
            min-height: 144px;
        }
        .img-small {
            max-height: 78px;
        }
    }

    .shopping {
        .box {
            min-height: 153px;
        }
    }
    .deadline-show-more{
        height: 250px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}