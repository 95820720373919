#llm-chatbot-container {
    position: inital;

    .llm-chat-form {
        background: transparent;
        top: 65px;
        bottom: auto;
        height: calc(100vh - 80px);
        max-width: none;
        max-height: none;
    }

    .llm-chatbot-header {
        display: none;
    }

    .zopim {
        width: 100% !important;
        height: 100% !important;
    }
}

body.chatbot-open {
    iframe {
        margin-top: env(safe-area-inset-top) !important;
        height: calc(100% - env(safe-area-inset-top) - env(safe-area-inset-bottom)) !important;
    }
}

[data-id="zsiqembed"] {
    top: env(safe-area-inset-top) !important;
    max-height: calc(100% - env(safe-area-inset-top) - env(safe-area-inset-bottom)) !important;
}