@import 'styles/colors';
@import 'styles/variables';

.steps-progress {
  border: 1px solid var(--primary-color);
  display: flex;
  height: 28px;
  border-radius: 14px;
  width: calc(100% - 40px);
  margin-left: auto;
  margin-right: auto;
  background-color: $white;
  overflow: hidden;
  div {
    transition: flex-grow 0.5s ease;
    position: relative;
    display: flex;
    align-items: center;
    flex-grow: 0;
    background-color: white;
    span {
      width: 26px;
      height: 26px;
      background-color: white;
      border-radius: 50%;
      font-weight: bold;
      text-align: center;
      color: $gray;
      border: 1px solid var(--primary-color);
      z-index: 2;
    }
    p {
      padding: 0 5px;
      line-height: 1;
      margin: 0;
      width: 10px;
      visibility: hidden;
    }
    &:last-child:not(.next):not(.active) {
      p {
        display: none;
      }
    }
    &.active {
      background-color: var(--primary-color);
      background-image: $degraded-principal;
      flex-grow: 1;
      span {
        color: inherit;
      }
      p {
        display: block;
        color: white;
        width: auto;
        visibility: visible;
      }
      &::after {
        content: '';
        position: absolute;
        top: 0;
        right: -13px;
        width: 13px;
        height: 100%;
        background-color: var(--primary-color);
        background-image: $degraded-principal;
        z-index: 1;
      }
    }
    &.done {
      background-color: var(--primary-color);
      background-image: $degraded-principal;
      flex-grow: 0;
    }
  }
}