.payments-out-of-hour {
  .hours {
      p {
          padding: 3px;
          margin: 0;
          &:not(:last-child):after {
              content: ',';
              margin-left: 3px;
          }
      }
  }
}