.splash-screen {
  position: fixed;
  top: 0;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: 99999;
  display: flex;
  justify-content: center;
  align-items: center;
  .splash-ios{
    width: 100%;
    height: 100%;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      width: auto;
      height: auto;
    }
  }

  img {
    width: 200px;
    margin-top: -60px;
  }

  &.splash-hidden {
    opacity: 0;
    transition: all 500ms linear 2s; // <- the last value defines transition-delay
  }

  .version {
    font-size: 10px;
    color: white;
    position: absolute;
    bottom: 5px;
    right: 5px;
  }

  video[poster] {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}