.payments-page {
  .searcher{
    display: flex;
    gap: 15px;
    & > div {
      flex: 1;
    }
    button {
      width: 45px;
    }
  }
}
