@import 'styles/colors';
@import 'styles/variables';

.avatar-wrapper {    
    margin: auto;
    display: flex;
    justify-content: center;

    img {
        width: 100px;
        height: 100px;
        border-radius: 50%;
        object-fit: contain;
        border: 1px solid $secondary;
    }

    .edit-icon {
        align-self: end;
        // margin-bottom: 20px;
        margin-left: -10px;
    }
}