@import 'colors';
@import 'variables';

/**
 * Bootstrap default variable colors
 *
 * $blue:    #0d6efd;
 * $green:   #198754;
 * $cyan:    #0dcaf0;
 * $yellow:  #ffc107;
 * $red:     #dc3545;
 * $gray-100: #f8f9fa;
 * $gray-600: #6c757d;
 * $gray-900: #212529;
 *
 **/

/**
 * Bootstrap default theme color variables
 *
 * $primary:       $blue;
 * $secondary:     $gray-600;
 * $success:       $green;
 * $info:          $cyan;
 * $warning:       $yellow;
 * $danger:        $red;
 * $light:         $gray-100;
 * $dark:          $gray-900;
 *
 **/

// Override default variables before the import

/* INPUTS */

$input-padding-y: 13px;
$input-padding-x: 25px;
$input-font-size: 16px;

$input-border-color: #1A1A1A;
$input-focus-border-color: #1A1A1A;
$input-focus-box-shadow: none;

$input-border-radius: 5px;
$input-border-radius-sm: 5px;
$input-border-radius-lg: 5px;

$form-label-font-size: 12px;
$form-label-margin-bottom: .25rem;
$form-label-color: $label-blue;

/* BUTTONS */
$btn-focus-width: 0;
$btn-focus-box-shadow: none;

$btn-border-radius: 100px;
$btn-border-radius-sm: 100px;
$btn-border-radius-lg: 100px;

$btn-padding-y: 0.75rem;
$btn-padding-x: 1rem;

$btn-font-family: $font-regular;
$body-color: #000000;

$container-padding-x: $container-padding-x;

// Import Bootstrap and its default variables
@import '~bootstrap/scss/bootstrap.scss';