@import 'styles/colors';
@import 'styles/variables';

.quoter {
  .question-diamonds {
    .answer-image {
      border: 2px solid transparent;
      cursor: pointer;
      border-radius: 50%;
      max-width: 100%;
      width: 44px;
      aspect-ratio: 1/1;
      display: flex;
      align-items: center;
      justify-content: center;

      &.purity,
      &.cut {
        width: 60px;
        height: 60px;
      }

      img {
        height: 100%;
      }
    }

    .selected {
      .answer-image {
        overflow: hidden;
        border-color: var(--primary-color);
      }
    }
  }

  .cars-quoter-box {
    .benefit-container {
      div {
        width: calc(100% - 30px);
      }

      svg {
        align-self: center;
      }
    }
  }

  .payment-logo {
    width: 90px;
  }

  .payment-input {
    stroke: $white;
    background-color: var(--primary-color);
    border-radius: 0.25rem;
    padding: 0.5rem;
  }

  .payment-input.disabled {
    background-color: $gray-light;
    border: 1px solid $gray;
    stroke: $gray;
    pointer-events: none;
  }

  .table-resume-loan{
    overflow-x: auto;
    table {
      width: 100%;
      td, th{
        padding: 0 10px;
      }
      th:nth-child(2),
      td:nth-child(2){
        border-width: 0 1px 0 1px;
        border-style: solid;
        border-color: var(--gray-color);
      }
    }
  }
}