.card {
  .order-1 {
    order: 1;
  }
  .order-0 {
    order: 0;
  }
  *:not(.a) {
    text-decoration: none;
  }
  .card-image{
    img {
      max-width: 150px;
      height: auto;
      max-height: 100%;
      &.effect-rotate {
        transform: rotateY(180deg);
      }
      &.size-xs {
        max-width: 50px;
      }
      &.size-sm {
        max-width: 100px;
      }
      &.size-lg {
        max-width: 200px;
      }
      &.size-xl {
        max-width: 240px;
      }
      &.size-xxl {
        max-width: 280px;
      }
    }
  }
}