@import '@fortawesome/fontawesome-free/css/all.min.css';
@import 'custom-theme';
@import 'normalize';
@import 'fonts';
@import 'variables';

:root {
  --primary-color: #{$primary};
  --primary-color-light: #{$primary-light};
  --secondary-color: #{$secondary};
  --gray-color: #{$gray};
}
* {
  -webkit-overflow-scrolling: touch;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

input {
  -webkit-appearance: none;
  appearance: none;
}

body {
  margin: 0;
  background-color: $gray-light;
  font-family: $font-regular, system-ui, -apple-system;
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding: 
    env(safe-area-inset-top)
    env(safe-area-inset-right)
    env(safe-area-inset-bottom)
    env(safe-area-inset-left);
}

.img-reverse {
  transform: scaleX(-1);
}

.container {
  padding-left: calc($container-padding-x * 0.5);
  padding-right: calc($container-padding-x * 0.5);
}

.container-app {
  min-height: calc(100vh - $header-height);
  padding-top: 1rem;
  padding-left: calc($container-padding-x * 0.5);
  padding-right: calc($container-padding-x * 0.5);
}

.layout {
  margin-top: $header-height;
  min-height: 100%;

  .layout-wrapper, .layout-content {
    min-height: 100%;
  }
}

@keyframes slideUp {
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0);
  }
}

.slide-up-enter {
  animation: slideUp 0.3s ease-out forwards;
}

.btn-primary {
  color: #fff;
  background-color: var(--primary-color, $primary);
  border-color: var(--primary-color, $primary);

  &:hover {
    color: var(--primary-color, $primary);
    background-color: #fff;
  }
}

.btn-secondary {
  color: #fff;
  background-color: var(--secondary-color, $secondary);
  border-color:  var(--secondary-color, $secondary);

  &:hover {
    color:  var(--secondary-color, $secondary);
    background-color: #fff;
  }
}


a,
.a {
  color: var(--primary, $primary);
  text-decoration: underline;
}

.embed-page{
  width: calc(100% + 2.5rem);
  height: calc(100vh - 74px - env(safe-area-inset-top) - env(safe-area-inset-bottom));
  margin-left: -1.25rem;
  &.with-menu {
    margin-top: -15px;
  }
}

.no-hover-btn {
  &:hover {
    background-color: var(--primary, $primary);
    color: inherit;
  }
}
.fill-success * {
  fill: $success !important;
}