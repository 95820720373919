@import 'styles/colors';

.ui-input {
  .prefix {
    display: flex;
    span{
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #fff;
      font-size: 16px;
      border-width: 1px 0px 1px 1px;
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
      border-color: $black;
      border-style: solid;
      padding: 0 7px;
    }
    input {
      padding-left: 10px;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }
  &.not-disabled {
    input {
      background-color: #fff;
    }
  }
}