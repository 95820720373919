@import 'styles/colors';

.btn {
  outline: none;
  width: 100%;
  border-radius: 5px;
  padding: 10px 15px;
  text-transform: uppercase;
  font-weight: bold;
  position: relative;

  &.btn-primary { 
    &:hover,
    &:focus,
    &:active,
    &:visited {
      background-color: var(--primary-color);
      color: #fff;
    }
  }
  &.btn-secondary { 
    background-color: #fff;
    color: var(--primary-color);
    border: 1px solid var(--primary-color);
  }
  &:disabled {
    background-color: $gray-light;
    color: $gray-dark;
    border: 1px solid $gray-dark;
  }
  &.loading {
    overflow: hidden;
    background-color: var(--primary-color-light) !important;
    cursor: wait;
    color: #fff !important;
    & > * {
      position: relative;
      z-index: 2;
    }

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 150%;
      height: 100%;
      background: repeating-linear-gradient(60deg,
              transparent,
              transparent 0.75rem,
              var(--primary-color) 0.75rem,
              var(--primary-color) 1.5rem);
      animation: load 0.75s infinite linear;
    }
    
    &.btn-secondary {
      color: var(--primary-color);
      background-color: #{rgba(var(--primary-color), 0.2)};
      &::before {
        background: repeating-linear-gradient(60deg,
                transparent,
                transparent 0.75rem,
                #{rgba(var(--primary-color), 0.1)} 0.75rem,
                #{rgba(var(--primary-color), 0.1)} 1.5rem);
      }
    }  
  }
}

@keyframes load {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(-1.75rem);
  }
}

.btn-size-xs {
  padding: 5px;
  text-transform: none;
}

.btn-size-sm {
  padding: 7px;
  text-transform: none;
}