@import 'styles/colors';

.ui-input-password {
  &__input {
    position: relative;
    padding-right: 2.5rem;
  }

  .btn-toggle-password {
    top: 0;
    right: 0.5rem;
    padding: 0.85rem 0;
    cursor: pointer;

    i {
      font-size: 1.25em;
      color: var(--primary-color);
    }
  }
}

.ui-input-password.error {

  label,
  input,
  i {
    color: $red;
  }
}