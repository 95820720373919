.payment-reference-resume {
    .separator {
        width: 100%;
        height: 1px;
        background: black;
    }
    .reference-container {
        h3 {
            word-break: break-word;
            text-align: right;
        }
    }
}

.paycash-partners-list{
    list-style-type: none;
    padding-left: 10px;
    li {
        display: flex;
        align-items: center;
        position: relative;
        padding-left: 20px;
        margin-bottom: 10px;
        p {
            margin: 0;
        }
        img {
            margin-left: 8px;
        }
        &::before {
            content: '';
            display: inline-block;
            width: 6px;
            height: 6px;
            background-color: black; /* Color del círculo */
            border-radius: 50%; /* Hace que sea un círculo */
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%); /* Centrar verticalmente el círculo */
        }
    }
  }

