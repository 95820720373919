.otp_input {
  input[type='number']::-webkit-outer-spin-button,
  input[type='number']::-webkit-inner-spin-button {
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
  }

  input[type='number'] {
    -moz-appearance: textfield;
    -webkit-appearance: textfield;
    appearance: textfield;
  }
}