@import 'styles/variables';
.simple-card-list-paginated {
  .pager{
    display: flex;
    justify-content: center;
    .pager-list {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      box-shadow: $shadow;
      border-radius: 5px;
      & > div {
        padding: 10px;
        border: 1px solid #dee2e6;
        background-color: white;
        border-width: 0px 1px 0px 0px;
        cursor: pointer;
        &.active {
          background-color: var(--primary-color);
          color: white;
        }
        &.disabled {
          cursor: not-allowed;
          opacity: 0.5;
        }
        &:first-child {
          border-radius: 5px 0 0 5px;
        }
        &:last-child {
          border-width: 0;
          border-radius: 0 5px 5px 0;
        }
      }
    }
  }
}