@import 'styles/colors';
@import 'styles/variables';

.feedback-modal {
    &__title {
        font-size: 24px;
        color: $label-blue;
        margin-top: 2rem;
        display: block;
        font-family: $font-bold;
    }

    &__description {
        color: $gray;
        font-size: 1rem;
    }
}