.block-rich-text {
  img {
    max-width: 100%;
  }
  .ql-align-center {
    text-align: center;
    display: flex;
    justify-content: center;
  }
  .ql-align-right {
    text-align: right;
    display: flex;
    justify-content: flex-end;
  }
}
.block-embed-document {
  iframe {
    width: 100%;
    height: 70vh;
  }
}