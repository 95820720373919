@import 'styles/variables';
@import 'styles/colors';

$vertical-small-padding: 15px;
$horizontal-small-padding: 15px;
$vertical-padding: 25px;
$horizontal-padding: 25px;
$vertical-large-padding: 45px;
$horizontal-large-padding: 35px;

.box {
  background-color: white;
  box-shadow: $shadow;
  border-radius: $radius;
  .box-tabs {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    & > * {
      padding: 10px $horizontal-padding;
      border-bottom: 2px solid $gray;
      text-align: center;
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      &.active {
        border-color: var(--primary-color);
      }
    }
  }
  .box-content {
    padding: $vertical-padding $horizontal-padding;
    width: 100%;
    .no-left-padding {
      margin-left: -$horizontal-padding;
    }
    .no-right-padding {
      margin-right: -$horizontal-padding;
    }
    .no-bottom-padding {
      margin-bottom: -$vertical-padding;
    }
    .no-top-padding {
      margin-top: -$vertical-padding;
    }
  }
  &.padding-sm {
    .box-content {
      padding: $vertical-small-padding $horizontal-small-padding;
      width: 100%;
      .no-left-padding {
        margin-left: -$horizontal-small-padding;
      }
      .no-right-padding {
        margin-right: -$horizontal-small-padding;
      }
      .no-bottom-padding {
        margin-bottom: -$vertical-small-padding;
      }
      .no-top-padding {
        margin-top: -$vertical-small-padding;
      }
    }
  }
  &.padding-lg {
    .box-content {
      padding: $vertical-large-padding $horizontal-large-padding;
      width: 100%;
      .no-left-padding {
        margin-left: -$horizontal-large-padding;
      }
      .no-right-padding {
        margin-right: -$horizontal-large-padding;
      }
      .no-bottom-padding {
        margin-bottom: -$vertical-large-padding;
      }
      .no-top-padding {
        margin-top: -$vertical-large-padding;
      }
    }
  }
}

.box.selected {
  border: 2px solid var(--primary-color);
  transition: border-color 0.5s ease;
}