@import 'styles/colors';
@import 'styles/variables';

.menu-switch-container {
    display: flex;
    justify-content: space-between;

    .menu-option {
        padding: 8px 0px;
        cursor: pointer;
        display: flex;
        gap: 3px;
        align-items: center;
        width: 50%;
        justify-content: center;
      }
      
      .menu-option.selected {
        border-bottom: 2px solid var(--primary-color);
        transition: border-bottom-color 0.5s ease;
      }

      .notification {
        background-color: var(--primary-color);
        color: $white;
        border-radius: 50%;
        width: 22px;
        height: 22px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 12px;
      }
}

.menu-switch-box {
    .box-content {
        padding: 0;
    }
}