@import 'styles/colors';

.password-rules {
  list-style: none;
  margin-left: 0;
  padding-left: 0;
  li{
    padding-left: 20px;
    position: relative;
    .dot {
      position: absolute;
      top: 7px;
      left: 0;
      display: inline-block;
      width: 10px;
      height: 10px;
      background-color: #ccc;
      border-radius: 50%;
      margin-right: 10px;
      &.ok {
        background-color: $success;
      }
    }
  }
}